'use client'

import React, { useMemo } from 'react'
import { createBrowserClient } from '@featureboard/js-sdk'
import { FeatureBoardProvider, useClient } from '@featureboard/react-sdk'

const createClient = (apiKey: string) =>
  createBrowserClient({
    environmentApiKey: apiKey,
    audiences: [] // minimal bootstrap set of audiences
  })

export default function FeatureBoardWrapper({
  apiKey,
  children
}: {
  apiKey: string
  children: React.ReactNode
}) {
  // initialize the FeatureBoard client
  const featueBoardClient = useMemo(() => createClient(apiKey), [apiKey])

  const { client, isInitialising, isError, error } = useClient({
    browserClient: featueBoardClient,
    audiences: ['plan-data-coalitions-professional-edition', 'role-platform-administrator'] // audience needs to be set here from user / org sub
  })

  return (
    <>{client ? <FeatureBoardProvider client={client}>{children}</FeatureBoardProvider> : null}</>
  )
}
