'use client'

import { Stack, Text } from '@mantine/core'
import { SortZA } from '@styled-icons/boxicons-regular'
import { useRouter } from 'next/navigation'
import { CSSProperties, ComponentType } from 'react'
import { ICON_SIZE_MENU } from '../../lib/icons'

export type MenuItemProps = {
  text: string
  to: string
  size: string | number | undefined
  color: string | undefined
  style?: CSSProperties | undefined
  textSize?: string | number | undefined
  Icon: ComponentType<{
    size: string | number | undefined
    color: string | undefined
    style?: CSSProperties | undefined
  }>
  cleanUp?: () => void
  disabled?: boolean
}

export default function MenuItem({
  size,
  color,
  text,
  Icon,
  to,
  style,
  textSize,
  cleanUp,
  disabled
}: MenuItemProps) {
  const router = useRouter()
  return (
    <Stack
      align="center"
      role="button"
      w="3.9rem"
      gap={'0.25rem'}
      onClick={() => {
        if (!disabled) {
          router.push(to)
          if (cleanUp) cleanUp()
        }
      }}
      style={{
        cursor: disabled ? 'not-allowed' : 'pointer',
        ':active': {
          transform: 'scale(0.95)',
          transition: 'transform 0.1s ease-in-out'
        }
      }}
    >
      <Icon size={ICON_SIZE_MENU} color={color} style={style} />
      <Text style={{ textAlign: 'center' }} size="sm">
        {text}
      </Text>
    </Stack>
  )
}
