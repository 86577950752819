'use client'

import { gql, useApolloClient } from '@apollo/react-hooks'
import { useAppContext } from './appContextProvider'
import { User } from '~/lib/generated/graphql'
import { useEffect } from 'react'
import { useSession } from 'next-auth/react'

const CURRENT_USER = gql`
  query CurrentUser {
    currentUser {
      id
      displayName
      email
      externalId
      primaryOrganization {
        id
        active
        displayName
      }
      permissions {
        platformPermissions {
          id
          code
          displayName
        }
        organizationPermissions {
          organization {
            id
            displayName
          }
          permissions {
            id
            code
            displayName
          }
        }
        coalitionPermissions {
          coalition {
            id
            displayName
            type
          }
          permissions {
            id
            code
            displayName
          }
        }
      }
      organizations {
        id
        displayName
      }
      userRoles {
        id
        code
        displayName
      }
    }
  }
`

export default function UserInitWrapper({ children }: { children: React.ReactNode }) {
  const { context, setContext } = useAppContext()
  const client = useApolloClient()
  const session = useSession()

  useEffect(() => {
    ;(async () => {
      let user = await client
        .query<{ currentUser: User }>({ query: CURRENT_USER, variables: {} })
        .then((res) => res.data.currentUser)

      setContext({ ...context, user: user })
    })()

    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <>{children}</>
}
