import { SpotlightActionData } from '@mantine/spotlight'
import { Home } from '@styled-icons/material'
import Link from 'next/link'
import { ICON_SIZE } from './icons'
import { useRouter } from 'next/navigation'
import { Group, Stack, Text } from '@mantine/core'

function ActionLink({
  icon,
  path,
  title,
  text
}: {
  icon: React.ReactNode
  path: string
  title: string
  text: string
}) {
  const router = useRouter()
  return (
    <Link href="path">
      <Group>
        {icon}
        <Stack gap={0}>
          <Text
            style={{
              color: 'black'
            }}
          >
            {title}
          </Text>
          <Text size="sm" style={{ color: 'var(--mantine-color-concrete-5)' }}>
            {text}
          </Text>
        </Stack>
      </Group>
    </Link>
  )
}

export const spotlightActions: SpotlightActionData[] = [
  {
    id: 'home',
    label: 'Home',
    description: 'Go to user workspace',
    children: (
      <ActionLink
        icon={<Home size={ICON_SIZE} style={{ fill: 'url(#brand-gradient)' }} />}
        path="/"
        title="Home2"
        text="Return to Workspace"
      />
    )
  }
]
