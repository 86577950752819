'use client'

import { Avatar, Box, Center, Drawer, Group, Image, LoadingOverlay, Menu } from '@mantine/core'
import { signOut, useSession } from 'next-auth/react'

import Link from 'next/link'
import { useAppContext } from '../components/wrapper/appContextProvider'
import { Menu as MenuIcon } from '@styled-icons/boxicons-regular'
import { useDisclosure, useMediaQuery } from '@mantine/hooks'
import DCMenu from './DCMenu'

import styles from './styles.module.css'

const logo = 'https://flussodevstorage.blob.core.windows.net/flussodc/public/logotype.small.png'

const TopLink: React.FC<{ text: string; to: string; active?: boolean; disabled?: boolean }> = ({
  text,
  to,
  active,
  disabled
}) => {
  return (
    <Center mx={12} px="2rem" py={12} className={`${styles['top-bar']} ${active ? 'active' : ''}`}>
      <Link href={to} style={{ textDecoration: 'none' }}>
        {text}
      </Link>
    </Center>
  )
}

export type TopNavItem = 'dashboard' | 'coalitions' | 'feed' | 'docs' | 'marketplace'

export interface TopBarProps {}

export default function TopBar({}: TopBarProps) {
  const { context } = useAppContext()
  const [opened, { open, close }] = useDisclosure(false)
  const { data: session } = useSession()

  return (
    <>
      <Group
        justify="space-between"
        px="1rem"
        style={{
          backgroundColor: 'var(--mantine-color-brand-6)'
        }}
      >
        <LoadingOverlay visible={session?.user === null} />
        <Box h="4.9rem" w="10rem">
          <Link href="/" passHref legacyBehavior>
            <Image src={logo} alt="Flusso logo" style={{ cursor: 'pointer' }} />
          </Link>
        </Box>
        {session?.user !== null && session?.user ? (
          <Group className={styles['top-bar-container']}>
            {/* <TopLink
              text="Dashboard"
              to="/dashboard"
              active={context.activeTopItem === 'dashboard'}
              disabled
            /> */}
            <TopLink text="Coalitions" to="/" active={context.activeTopItem === 'coalitions'} />
            <TopLink
              text="Marketplace"
              to="/marketplace"
              active={context.activeTopItem === 'marketplace'}
            />
            {/* <TopLink text="Feed" to="/" active={context.activeTopItem === 'feed'} /> */}
            <TopLink text="Docs" to="/docs" active={context.activeTopItem === 'docs'} />
          </Group>
        ) : null}

        {session?.user !== null && session?.user !== undefined ? (
          <Group align="center">
            <Menu shadow="md">
              <Menu.Target>
                <Avatar
                  style={{ cursor: 'pointer' }}
                  src={session.user?.image}
                  size="md"
                  radius="xl"
                  alt={session.user?.name ?? 'unkown user'}
                />
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Label>{session.user?.name}</Menu.Label>
                <Menu.Item onClick={() => signOut()}>Sign Out</Menu.Item>
              </Menu.Dropdown>
            </Menu>

            <Box role="button" style={{ cursor: 'pointer' }}>
              <MenuIcon color="white" size={48} onClick={open} />
            </Box>
            {/* <MainOptions /> */}
          </Group>
        ) : null}
        <Drawer
          position="right"
          size="lg"
          opened={opened}
          onClose={close}
          title="Data Coalitions"
          transitionProps={{ transition: 'slide-left', duration: 200, timingFunction: 'linear' }}
        >
          <DCMenu close={close} />
        </Drawer>
      </Group>
    </>
  )
}
