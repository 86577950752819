'use client'

import { MantineProvider } from '@mantine/core'
import { ModalsProvider } from '@mantine/modals'
import { theme } from '../../lib/theme'
import { Notifications } from '@mantine/notifications'
import { Spotlight, SpotlightActionData, spotlight } from '@mantine/spotlight'
import { spotlightActions } from '~/lib/spotlightActions'

export default function MantineWrapper({ children }: { children: React.ReactNode }) {
  return (
    <MantineProvider theme={theme}>
      <Notifications />
      <ModalsProvider>
        <Spotlight actions={spotlightActions} />
        {children}
      </ModalsProvider>
    </MantineProvider>
  )
}
