import {
  ActionIcon,
  Button,
  Input,
  MantineThemeOverride,
  Menu,
  Modal,
  MultiSelect,
  Stack,
  Text
} from '@mantine/core'
import { OpenConfirmModal } from '@mantine/modals/lib/context'

export const theme: MantineThemeOverride = {
  /** Put your mantine theme override here */

  // activeStyles: {
  //   transition: 'transform 100ms ease',
  //   transform: 'scale(0.95)'
  // },
  // colorScheme: 'light',
  colors: {
    brand: [
      '#f1fcfa',
      '#d0f7f4',
      '#a1eee9',
      '#6bddda',
      '#3cc4c5',
      '#26b5b8',
      '#198488',
      '#18696d',
      '#185357',
      '#184749'
    ],
    concrete: [
      '#fafafa',
      '#f2f2f2',
      '#e6e6e6',
      '#d6d6d6',
      '#a5a5a5',
      '#767676',
      '#575757',
      '#434343',
      '#292929',
      '#1a1a1a'
    ]
  },
  fontSizes: {
    xs: '1.4',
    sm: '1.45',
    md: '1.55',
    lg: '1.6',
    xl: '1.65'
  },

  headings: {
    fontFamily: 'var(--font-monument-regular)',
    fontWeight: '500',
    sizes: {
      h1: { fontSize: '2rem', fontWeight: '700' },
      h2: { fontSize: '1.5rem', fontWeight: '700' },
      h3: { fontSize: '1.1rem', fontWeight: '500' }
    }
  },

  components: {
    ActionIcon: ActionIcon.extend({
      classNames: {
        root: 'action-icon'
      }
    }),
    Button: Button.extend({
      classNames: {
        root: 'button',
        label: 'button-inner'
      }
    }),
    Input: Input.extend({
      classNames: {
        wrapper: 'input'
      }
    }),
    Menu: Menu.extend({
      classNames: {
        item: 'menu-item'
      }
    }),
    Modal: Modal.extend({
      classNames: {
        root: 'modal',
        title: 'modal-title',
        header: 'modal-header'
      }
    }),
    MultiSelect: MultiSelect.extend({
      classNames: {
        input: 'multi-select-input'
      }
    }),
    Stack: Stack.extend({
      classNames: {
        root: 'stack'
      }
    }),
    Text: Text.extend({
      classNames: {
        root: 'text'
      }
    })
  }
}

export const sharedModalSetting = (setting: OpenConfirmModal): OpenConfirmModal => ({
  ...setting,
  confirmProps: { color: 'brand', ...setting?.confirmProps },
  cancelProps: { variant: 'filled', color: 'gray', ...setting?.cancelProps }
})

// export const outlineButtonStyle = (theme: MantineTheme) => ({
//   backgroundColor: 'white',
//   borderColor: theme.colors.brand[4],
//   borderWidth: '1px',
//   color: theme.colors.brand[4],
//   '&:hover': {
//     backgroundColor: theme.colors.brand[4],
//     color: 'white'
//   }
// })

// export const removeButtonStyle = (theme: MantineTheme) => ({
//   backgroundColor: theme.colors.red[6],
//   '&:hover': {
//     backgroundColor: theme.colors.red[9]
//   }
// })
