import { Group, Text } from '@mantine/core'
import Link from 'next/link'
import { useAppContext } from '../wrapper/appContextProvider'

export interface Crumb {
  text: string
  link: string
}

// export interface BreadcrumbProps {
//   crumbs: Crumb[]
// }

export const Breadcrumb = () => {
  const { context } = useAppContext()

  return (
    <Group style={{ paddingBottom: '2px', lineHeight: 0 }}>
      {context.crumbs.map((crumb, index) => {
        if (index === context.crumbs.length - 1) {
          return (
            <Text c="dimmed" key={index}>
              {crumb.text}
            </Text>
          )
        } else
          return (
            <Group
              key={index}
              style={(theme) => ({
                a: { cursor: 'pointer', color: theme.colors['brand'][5], textDecoration: 'none' }
              })}
            >
              <Link href={crumb.link} passHref>
                {crumb.text}
              </Link>
              <p>{'>'}</p>
            </Group>
          )
      })}
    </Group>
  )
}
